var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SectionContainer',{class:{heroTypeSectionContainer: _vm.blok.isHeroWithAnimation},attrs:{"paddingTop":_vm.blok.paddingTop,"paddingBottom":_vm.blok.paddingBottom}},[_c('div',{staticClass:"main-content"},[(_vm.blok.backgroundImageDesktop && _vm.blok.backgroundImageDesktop.filename)?_c('img',{staticClass:"background-image-desktop",class:{ 'full-width': _vm.blok.fullWidthBackground },style:({ objectPosition: _vm.blok.backgroundImagePosition || 'center' }),attrs:{"src":_vm.blok.backgroundImageDesktop.filename,"alt":_vm.blok.backgroundImageDesktop.alt || _vm.blok.backgroundImageDesktop.filename}}):_vm._e(),_vm._v(" "),(_vm.blok.backgroundImageMobile && _vm.blok.backgroundImageMobile.filename)?_c('img',{staticClass:"background-image-mobile",class:{ 'full-width': _vm.blok.fullWidthBackground },style:({ objectPosition: _vm.blok.backgroundImagePosition || 'center' }),attrs:{"src":_vm.blok.backgroundImageMobile.filename,"alt":_vm.blok.backgroundImageMobile.alt || _vm.blok.backgroundImageMobile.filename}}):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"editable",rawName:"v-editable",value:(_vm.blok),expression:"blok"}],staticClass:"grid",class:{
        'align-left': _vm.blok.snippetPosition === 'right',
        'align-right': _vm.blok.snippetPosition === 'left',
      },attrs:{"id":_vm.blok.id}},[_c('div',{staticClass:"content",class:{
          center:
            (_vm.blok.centerVertically === 'content' ||
              _vm.blok.centerVertically === 'content-and-snippet') &&
            _vm.blok.snippetPosition !== 'center',
        }},[(_vm.blok.showAdditionalInfo)?_c('Heading',{staticClass:"additionalText",class:_vm.blok.additionalInfoFontSize,style:(_vm.additionalInfoStyle),attrs:{"tag":_vm.blok.additionalInfoTag}},[_vm._v("\n          "+_vm._s(_vm.blok.additionalInfoText)+"\n        ")]):_vm._e(),_vm._v(" "),_c('Heading',{staticClass:"title",class:_vm.blok.titleFontSize,attrs:{"tag":_vm.blok.titleTag}},[_vm._v("\n          "+_vm._s(_vm.blok.title)+"\n        ")]),_vm._v(" "),(!!_vm.blok.text)?_c('RichTextV2',{attrs:{"text":_vm.blok.text}}):_vm._e()],1),_vm._v(" "),(_vm.hasCodeSnippets)?_c('div',{staticClass:"snippet",class:{
          center:
            _vm.blok.centerVertically === 'snippet' ||
            _vm.blok.centerVertically === 'content-and-snippet',
        },attrs:{"id":_vm.blok._uid}},[(_vm.hasCss)?_c('style',[_vm._v(_vm._s(_vm.cssText))]):_vm._e(),_vm._v(" "),(_vm.hasHtml)?_c('div',{staticClass:"insertedHtml",domProps:{"innerHTML":_vm._s(_vm.blok.html)}}):_vm._e(),_vm._v(" "),(
            _vm.blok.snippetBackgroundImage && _vm.blok.snippetBackgroundImage.filename
          )?_c('img',{staticClass:"snippet-background-image",attrs:{"src":_vm.blok.snippetBackgroundImage.filename,"alt":_vm.blok.snippetBackgroundImage.alt ||
            _vm.blok.snippetBackgroundImage.filename}}):_vm._e()]):_vm._e()])]),_vm._v(" "),(_vm.blok.lottieBackgroundJSON)?_c('lottie-player',{staticClass:"lottie-desktop",attrs:{"src":_vm.blok.lottieBackgroundJSON,"background":"transparent","speed":"1","loop":"","autoplay":""}}):_vm._e(),_vm._v(" "),(_vm.blok.lottieBackgroundMobileJSON)?_c('lottie-player',{staticClass:"lottie-mobile",attrs:{"src":_vm.blok.lottieBackgroundMobileJSON,"background":"transparent","speed":"1","loop":"","autoplay":""}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }