












































































































import Vue, { PropOptions } from 'vue';
import type { HeadingTag } from '@/components/Heading.vue';
import type { PaddingOptions } from '@/components/SectionContainer.vue';

export type ContentWithCodeSnippet = Blok & {
  id: string;
  showAdditionalInfo?: true;
  additionalInfoText?: string;
  additionalInfoFontSize?: string;
  additionalInfoColor?: string;
  additionalInfoTag: HeadingTag;
  title?: string;
  titleFontSize?: string;
  titleTag: HeadingTag;
  text?: string;
  html: string;
  css: string;
  javascript: string;
  snippetPosition?: 'left' | 'center' | 'right';
  snippetBackgroundImage?: BlokFieldAsset;
  backgroundImageDesktop?: BlokFieldAsset;
  backgroundImageMobile?: BlokFieldAsset;
  backgroundImagePosition?: string;
  fullWidthBackground?: boolean;
  paddingTop?: PaddingOptions;
  paddingBottom?: PaddingOptions;
  centerVertically?: 'nothing' | 'content' | 'snippet' | 'content-and-snippet';
  isHeroWithAnimation?: boolean;
  lottieBackgroundJSON?: string;
  lottieBackgroundMobileJSON?: string;
};

export default Vue.extend({
  props: {
    blok: {
      type: Object,
      required: true,
    } as PropOptions<ContentWithCodeSnippet>,
  },
  computed: {
    hasHtml(): boolean {
      return typeof this.blok.html === 'string' && this.blok.html.length > 0;
    },
    hasCss(): boolean {
      return typeof this.blok.css === 'string' && this.blok.css.length > 0;
    },
    hasJavascript(): boolean {
      return (
        typeof this.blok.javascript === 'string' &&
        this.blok.javascript.length > 0
      );
    },
    hasCodeSnippets(): boolean {
      return this.hasHtml || this.hasCss || this.hasJavascript;
    },
    additionalInfoStyle(): {
      color?: string;
    } {
      return {
        color: this.blok.additionalInfoColor
          ? `var(--${this.blok.additionalInfoColor})`
          : undefined,
      };
    },
    cssText(): string {
      return this.hasCss ? this.blok.css : '';
    },
  },
  mounted() {
    this.injectJavascript();
  },
  methods: {
    injectJavascript() {
      if (this.hasJavascript) {
        const scriptTag = document.createElement('script');
        scriptTag.textContent = `(function(){
          ${this.blok.javascript}
        })();`;
        document.body.appendChild(scriptTag);
      }
    },
  },
});
