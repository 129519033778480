import Vue from 'vue';
import GhostButton from '~/components/GhostButton.vue';
import NavBar from '~/components/NavBar.vue';
import CalendlyForm from '~/components/CalendlyForm.vue';
import Article from '~/components/Article.vue';
import HeroBottom from '~/components/HeroBottom.vue';
import GuidesAndTutorialsPage from '~/components/GuidesAndTutorialsPage/GuidesAndTutorialsPage.vue';
import HorizontalScrollerContainer from '~/components/HorizontalScroller/HorizontalScrollerContainer.vue';
import CodeItemImage from '~/components/Code/elements/CodeItemImage.vue';
import Notification from '~/components/Notification.vue';
import NotFound from '~/components/NotFound.vue';
import Title from '@/components/Title.vue';
import Subtitle from '~/components/Subtitle';
import ImageHoverable from '@/components/Media/ImageHoverable.vue';
import MemberImage from '@/components/Media/MemberImage.vue';
import HomePage from '@/components/Pages/HomePage.vue';
import Link from '@/components/Buttons/Link.vue';
import HomePageMainHeroFeature from '@/components/Heros/HomePageMainHeroFeature.vue';
import Footer from '@/components/Sections/Footer.vue';
import Image from '@/components/Media/Image.vue';
import Button from '@/components/Buttons/Button.vue';
import ButtonIcon from '@/components/Buttons/ButtonIcon.vue';
import Heading from '@/components/Heading.vue';
import SectionContainer from '@/components/SectionContainer.vue';
import RichTextV2 from '@/components/RichText/RichTextV2.vue';
import DropdownItem from '@/components/Dropdown/DropdownItem.vue';
import Buttons from '@/components/storyblok/Buttons.vue';
import ContentWithMedia from '@/components/storyblok/ContentWithMedia.vue';
import ContentWithCodeSnippet from '@/components/storyblok/ContentWithCodeSnippet.vue';
import BannerAlert from '@/components/storyblok/BannerAlert.vue';

Vue.component('BlokImage', Image);
Vue.component('BlokImageHoverable', ImageHoverable);
Vue.component('BlokMemberImage', MemberImage);
Vue.component('CalendlyForm', CalendlyForm);
Vue.component('NotFound', NotFound);
Vue.component('NewHome', HomePage);
Vue.component('BlokLink', Link);
Vue.component('BlokMainHeroFeature', HomePageMainHeroFeature);
Vue.component('BlokTitle', Title);
Vue.component('BlokSubtitle', Subtitle);
Vue.component('Notification', Notification);
Vue.component('BlokGhostButton', GhostButton);
Vue.component('BlokNavBar', NavBar);
Vue.component('BlokArticle', Article);
Vue.component('BlokHeroBottom', HeroBottom);
Vue.component('BlokGuidesAndTutorialsPage', GuidesAndTutorialsPage);
Vue.component('BlokHorizontalScrollerContainer', HorizontalScrollerContainer);
Vue.component('BlokFooter', Footer);
Vue.component('BlokCodeItemImage', CodeItemImage);
Vue.component('Button', Button);
Vue.component('ButtonIcon', ButtonIcon);
Vue.component('Heading', Heading);
Vue.component('SectionContainer', SectionContainer);
Vue.component('RichTextV2', RichTextV2);
Vue.component('DropdownItem', DropdownItem);
Vue.component('BlokButtons', Buttons);
Vue.component('BlokContentWithMedia', ContentWithMedia);
Vue.component('BlokContentWithCodeSnippet', ContentWithCodeSnippet);
Vue.component('BannerAlert', BannerAlert);
